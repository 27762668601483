<template>
  <form class="card auth-card" @submit.prevent="sendForm">
    <div class="card-content">
      <span class="card-title">{{$t('authBlock.WellcomeTo',{name:'DY-CRM'})}}</span>
      <div class="input-field">
        <input
          id="email"
          type="text"
          v-model.trim="email"
          :class="{invalid: ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}"
        >
        <label for="email">{{$t('authBlock.Email')}}</label>
        <small
          class="helper-text invalid"
          v-if="($v.email.$dirty && !$v.email.required)"
        >{{$t('authBlock.FieldCouldntBeEmpty')}}</small>
        <small
          class="helper-text invalid"
          v-else-if="($v.email.$dirty && !$v.email.email)"
        >{{$t('authBlock.EmailIncorrect')}}</small>
      </div>
    </div>
    <div class="card-action">
      <div>
        <button
          class="btn waves-effect waves-light auth-submit"
          type="submit"
        >
          {{$t('authBlock.Restore')}}
          <i class="material-icons right">send</i>
        </button>
      </div>
      <p class="center">
        {{$t('authBlock.NotWithUs')}}
        <router-link to="/register">{{$t('authBlock.Register')}}</router-link>
      </p>
    </div>
  </form>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'
export default {
  name: 'ForgotPassword',
  data () {
    return {
      email: ''
    }
  },
  metaInfo () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Restore password'
    }
  },
  validations: {
    email: { email, required }
  },
  methods: {
    async sendForm () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const formData = {
        email: this.email
      }
      try {
        await this.$store.dispatch('forgotPassword', formData)
        this.$router.push('/login')
      } catch (e) {}
    }
  }
}
</script>

<style scoped>

</style>
